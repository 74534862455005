/* import all utilities scss */
/*
 * @ Variables
 *
*/
/*
 *  Farah Typograpy
*/
/*
 *  Farah base Font sizex
*/
/*
 * H1
*/
/* Russian */
/*Chinese*/
/*
 * H2
*/
/* Russian */
/*Chinese*/
/*
 * H3
*/
/* Russian */
/*Chinese*/
/*
/*
 * H4
*/
/*Russian*/
/*Chinese*/
/*
 * H5
*/
/* Russian */
/*Chinese*/
/*
 * H6
*/
/* Russian */
/*Chinese*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 *  Base Container
*/
/*
 *  Z-index Of components
*/
/* Please do not give z-index more than these z-Index */
/*
 *  Farah desktop font Sizes
*/
/*
 *  Farah mobile font Sizes
*/
/*
 *  Radius
*/
/*
 *  Use Defaults
*/
/*
 *  Base Alignments
*/
/*Hero Map component*/
/*input range*/
/**
    New Summary Cart
*/
/*
 * New Design System - 2025
 *
*/
/* Colors */
/* footer */
/* Editoiral Grid */
/*Carousel with Preview*/
/*
*  My profile
*/
/*
 *  Generic Colors
*/
/*
 *  Farah Base primary colors
*/
/*
 *  Farah Supporting colors
*/
/*
 *  Yas WaterWorld Base primary colors
*/
/*
 *  Yas Island Base primary colors
*/
/*
 *  Yas Ferrari World Base primary colors
*/
/*
 * Farah Font/buttons Color
*/
/*
 * Farah Font/buttons Color
*/
/**
Hotel Booking
*/
/**
tab with placeholder
*/
/**
tab feature list
*/
/*media carousel overlay*/
/*calendar dropdown*/
/* Trip Advisor Color */
/*overlay*/
/* Flash-Sale */
/*
* form placeholder color variables
*/
/*
 * @ Mixins
 *
*/
/*
* Background Image
*/
/*
* Configure vendor
*/
/*
* Line height font
*/
/*
* Clearfix
*/
/*
* Text Nowrap
*/
/*
* Font Smoothing
*/
/*
* Transition
  usage example
    a{
    color: gray;
    @include transition(color .3s ease);
    &:hover {
      color: black;
    }
}
*/
/*
* Background Gradient
*/
/*
* Background Horizontal
*/
/*
* Background Radial
*/
/*
* Background Size
*/
/*
* Background Color Opacity
*/
/*
* Border Radius
*/
/*
* Border Radius Separate
*/
/*
* Box
*/
/*
* Box Rgba
*/
/*
* Box Shadow
*/
/*
* Box Sizing
*/
/*
* Columns
*/
/*
* Flex
*/
/*
* Flip
*/
/*
* Opacity
*/
/*
* Resize
*/
/*
* Rotate
*/
/*
* Text Shadow
*/
/*
* Transform
*/
/*
* Keyframes
 Usage Example
  @include keyframes(slide-down) {
    0% { opacity: 1; }
    90% { opacity: 0; }
  }
*/
/*
* Animation
Usage Example
 .element {
    @include animation('slide-down 5s 3');
  }
*/
/*
* Widescreen
*/
/*
* Desktop (PC's)
*/
/*
* Desktop (PC's)
*/
/*
* Wide screen Desktop (PC's)
*/
/*
  * large screen Desktop (PC's)
  */
/*
* Tablet
*/
/*
  * TabletOnly
  */
/*
* Mobile
*/
/*
* upto Tablet
*/
/*
* No Screen
*/
/**
**  how to Use Mixin for tablet-landscape
**
.example{
  left: 35px;
  @include tablet-landscape{
      left: 100px;
  }
}
**/
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
* Font Face
*/
/*
* Placeholder Styling
*/
/*half circle left right for ticket tiles*/
/**
* Desc: Create equilateral triangle used in header on hover and focus
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * @ Text
 *
*/
/* lang ar */
/* lang ru */
/**
* Heading 5 has two versions
* Bold and Black
* Use heading-ru-5-bold and heading-ru-5-black
**/
/**
* Heading 6 has two versions
* Bold and Black
* Use heading-ru-6-bold and heading-ru-6-black
**/
/* lang zh */
/**
* Heading 5 has two versions
* Bold and Black
* Use heading-zh-5-bold and heading-zh-5-black
**/
/**
* Heading 6 has two versions
* Bold and Black
* Use heading-zh-6-bold and heading-zh-6-black
**/
/* lang all */
:root {
  --font-weight-base: 400;
}

body.lang-ar {
  --font-weight-base: 700;
}

/*
 * @ Gradients
 *
*/
/*
 * Line height font
 */
.jss-form-wrapper .component {
  margin-bottom: 50px;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .jss-form-wrapper .component {
    margin-bottom: 60px;
  }
}
@media only screen and (min-width: 1366px) {
  .jss-form-wrapper .component {
    width: 100%;
    max-width: 1366px;
  }
}
.jss-form-wrapper .c-form-wrapper {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .jss-form-wrapper .c-form-wrapper {
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 1366px) {
  .jss-form-wrapper .c-form-wrapper {
    width: 64.8484848485%;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 0 auto;
  }
}
.jss-form-wrapper .form-element {
  margin: 0 0 20px;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .jss-form-wrapper .form-element {
    margin: 0 0 24px;
  }
}
.jss-form-wrapper .form-element .error-msg {
  position: absolute;
  color: #ff1857;
  line-height: 1.4;
}
.jss-form-wrapper .form-element input,
.jss-form-wrapper .form-element select,
.jss-form-wrapper .form-element textarea {
  color: #333;
  font-size: 16px;
  width: 100%;
}
.jss-form-wrapper .form-element input.error,
.jss-form-wrapper .form-element select.error,
.jss-form-wrapper .form-element textarea.error {
  border-color: #ff1857;
}
.jss-form-wrapper .form-element input, .jss-form-wrapper .form-element input:focus,
.jss-form-wrapper .form-element select,
.jss-form-wrapper .form-element select:focus,
.jss-form-wrapper .form-element textarea,
.jss-form-wrapper .form-element textarea:focus {
  padding: 16px 15px 6px 15px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .jss-form-wrapper .form-element input, .jss-form-wrapper .form-element input:focus,
  .jss-form-wrapper .form-element select,
  .jss-form-wrapper .form-element select:focus,
  .jss-form-wrapper .form-element textarea,
  .jss-form-wrapper .form-element textarea:focus {
    padding-bottom: 6px;
    padding-top: 24px;
  }
}
.jss-form-wrapper .form-element.form-element-salutation {
  width: 60%;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .jss-form-wrapper .form-element.form-element-salutation {
    width: 27.5%;
  }
}
@media only screen and (min-width: 1024px) {
  .jss-form-wrapper .form-element.form-element-left, .jss-form-wrapper .form-element.form-element-right {
    width: 49%;
    margin-left: 0;
    margin-right: 1%;
    display: inline-block;
    vertical-align: middle;
  }
  .lang-ar .jss-form-wrapper .form-element.form-element-left, .lang-ar .jss-form-wrapper .form-element.form-element-right {
    margin-left: 1%;
    margin-right: 0;
  }
  .jss-form-wrapper .form-element.form-element-right {
    margin-left: 1%;
    margin-right: 0;
  }
  .lang-ar .jss-form-wrapper .form-element.form-element-right {
    margin-left: 0;
    margin-right: 1%;
  }
}
.jss-form-wrapper .server-error {
  width: 95.2380952381%;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  margin: 20px auto;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .jss-form-wrapper .server-error {
    width: 64.8484848485%;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 20px auto;
  }
}
@media only screen and (min-width: 1366px) {
  .jss-form-wrapper .server-error {
    width: 56.5151515152%;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 20px auto;
  }
}
.jss-form-wrapper .server-error:before {
  left: calc(50% - 12px);
}
.jss-form-wrapper.B2C-bookDate .contact-us-form .c-my-cart-b2c-container .checkout-button .login-signup .form-wrapper-buttons-anchor {
  font-size: 20px;
  font-size: 1.25rem;
  /* Medium devices (desktops, 1024px and up) */
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  line-height: 29px;
}
@media only screen and (min-width: 1024px) {
  .jss-form-wrapper.B2C-bookDate .contact-us-form .c-my-cart-b2c-container .checkout-button .login-signup .form-wrapper-buttons-anchor {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
.lang-ar .jss-form-wrapper.B2C-bookDate .contact-us-form .c-my-cart-b2c-container .checkout-button .login-signup .form-wrapper-buttons-anchor {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.jss-form-wrapper.B2C-bookDate .contact-us-form .c-my-cart-b2c-container .checkout-button .login-signup .form-wrapper-buttons-anchor:after {
  left: 0;
  content: "";
  position: absolute;
  width: 100%;
  border-bottom: 2px solid #ffd90b;
  top: calc(100% - 2px);
}